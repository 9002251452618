import { useStaticQuery, graphql } from "gatsby";
import { groupBy } from "rambdax";

export const useFetchAllUnitedContent = () => {
  const { allMdx } = useStaticQuery(
    graphql`
      query FetchAllUnitedContent {
        allMdx(
          sort: { fields: frontmatter___order, order: ASC }
          filter: { fileAbsolutePath: { regex: "/.*pages/united/.*/" } }
        ) {
          nodes {
            slug
            body
            frontmatter {
              order
              group
              year
              title
              copy
              mediaType
              mediaArgs
              ctaText
              ctaIcon
              ctaLink
              src
              poster
              trackingLabel
              autoplay
              fieldOfView
              samples {
                title
                description
                language
                start
                stop
              }
              partners {
                title
                copy
                cause
                location
                link
                logo {
                  childImageSharp {
                    gatsbyImageData(
                      width: 196
                      layout: FIXED
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
            fileAbsolutePath
          }
        }
      }
    `,
  );

  if (allMdx?.nodes) {
    return groupBy(node => node.frontmatter.group, allMdx.nodes);
  } else {
    return [];
  }
};
