import React from "react";
import styled from "styled-components";
import { slugify } from "~src/utils/strings";
import Layout from "~components/layouts/united";
import IntroSection from "~components/ui/intro-section";
import ContentSection from "~components/ui/content-section";
import { useFetchAllUnitedContent } from "~hooks/use-fetch-all-united-content";
import IntroBg1 from "~assets/images/2024-resurgens-kit-bg.png";
import IntroBg2 from "~assets/images/intro-bg2-full.webp";

const team = "united";

const SectionItem = ({ section, i, sections }) => {
  const samples =
    section.frontmatter.partners && section.frontmatter.partners.length > 0
      ? section.frontmatter.partners
      : section.frontmatter.samples;
  // unity kit section is the same year as another section
  const name =
    section?.frontmatter?.mediaType === "unity-kit"
      ? "unity"
      : section.frontmatter.year;
  const isLast = i === sections.length - 1;
  const isFirst = i === 0;
  const isEven = i % 2 === 0;
  const id = `section-${slugify(name)}`;
  const nextSectionUrl = isLast
    ? null
    : `/${team}#section-${slugify(sections[i + 1].frontmatter.year)}`;

  return (
    <ContentSection
      key={`section-${section.slug}`}
      slug={section.slug}
      id={id}
      nextSectionUrl={nextSectionUrl}
      body={section.body}
      year={section.frontmatter.year}
      title={section.frontmatter.title}
      copy={section.frontmatter.copy}
      mediaType={section.frontmatter.mediaType}
      mediaArgs={section.frontmatter.mediaArgs}
      ctaText={section.frontmatter.ctaText}
      ctaIcon={section.frontmatter.ctaIcon}
      ctaLink={section.frontmatter.ctaLink}
      src={section.frontmatter.src}
      poster={section.frontmatter.poster}
      trackingLabel={section.frontmatter.trackingLabel}
      autoplay={section.frontmatter.autoplay}
      fieldOfView={section.frontmatter.fieldOfView}
      samples={samples}
      index={i}
      isLast={isLast}
      isFirst={isFirst}
      right={!isEven}
    />
  );
};

const IndexPage = () => {
  const sections = useFetchAllUnitedContent();
  const current = sections?.["current"];
  const past = sections?.["past"];

  return (
    <Layout>
      <MainScrollContainer>
        <IntroSection
          id='top'
          title='GOING BACK TO OUR ROOTS'
          copy='INTRODUCING THE 2024 RESURGENS KIT'
          bgSrc={IntroBg1}
          bgX='center'
          nextSectionUrl={`/${team}#section-${current[0].frontmatter.year}`}
        />

        {current &&
          current.length > 0 &&
          current.map((section, i) => {
            return (
              <SectionItem
                key={`section-${section.slug}`}
                section={section}
                sections={current}
                i={i}
              />
            );
          })}

        <IntroSection
          id='kit-history'
          title='KIT HISTORY'
          copy='2017–2023'
          bgSrc={IntroBg2}
          nextSectionUrl={`/${team}#section-${past[0].frontmatter.year}`}
        />

        {past &&
          past.length > 0 &&
          past.map((section, i) => {
            return (
              <SectionItem
                key={`section-${section.slug}`}
                section={section}
                sections={past}
                i={i}
              />
            );
          })}
      </MainScrollContainer>
    </Layout>
  );
};

// const BottomSpacer = styled.div`
//   width: 100%;
//   height: 12vh;
// `;

const MainScrollContainer = styled.main`
  /* scroll-snap-type: y mandatory;
  scroll-behavior: smooth; */
  /* -webkit-overflow-scrolling: touch; */
`;

export default IndexPage;
